import { useRouter } from "next/router";
import { useEffect, useRef, useState } from "react";
import Image from "next/image";
import CookieCutter from "cookie-cutter";
import { logAnalyticsEvent } from "@/utils/logAnalyticsEvent";
import { v4 as uuidv4 } from "uuid";
import CustomDatePicker from "./CustomDatePicker";
import { setUserProperties } from "firebase/analytics";
import { analytics } from "@/utils/firebase";
import SearchInputBox from "./SearchInputBox";
import dateFormat from "dateformat";
import Features from "./Features";
import getURL from "@/utils/getUrl";
import SearchPage from "./searchPage";
import Navbar from "../navbar";
import classNames from "classnames";
import ToggleSwitch from "./ToggleSwitch";
import { airportStore, dropoffStore, localeStore, popupStore } from "@/stores";
import { observer } from "mobx-react-lite";
import { findLocale } from "@/locales";
import { saveUser } from "@/utils/saveUser";
import { autorun } from "mobx";
import axios from "axios";
import { useAd } from "@/hooks/useAd";
import { usePickup } from "@/hooks/usePickup";
import AdLoader from "@/components/common/ad-loader";

type Props = {
  headText?: string;
  descText?: string;
  locale?: string;
  sid?: string;
  type: string;
  locationName?: string;
  ctid?: string;
  airport?: string;
};

const SearchBox = ({ headText, descText, locale = "en", sid, type, locationName, ctid: cityId, airport, }: Props) => {
  const router = useRouter();
  const isInAppBrowser = router.query.inapp;
  const [lp3Submitted, setLp3Submitted] = useState(false);
  const [locationId, setLocationId] = useState("");
  const [userId, setUserId] = useState("");
  const [isDropOffEnabled, setIsDropOffEnabled] = useState(false);
  const [selectedSearch, setSelectedSearch] = useState(0);
  const { adData, setAdData } = useAd();
  const { pickupLocation } = usePickup();
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date(startDate.getFullYear(), startDate.getMonth(), startDate.getDate() + 1));
  const storedSid = useRef(null);
  const [dropOffLocation, setDropOffLocation] = useState("");
  const [togglePickUpSearchPage, setTogglePickUpSearchPage] = useState(false);
  const [toggleDropOffSearchPage, setToggleDropOffSearchPage] = useState(false);
  const [region, setRegion] = useState("");
  const [clickId, setClickId] = useState("anon");
  const [priceDropAlertToggle, setPriceDropAlertToggle] = useState(true);

  const tabKeyDownListener = (e: KeyboardEvent) => { if (e.code === "Tab") logAnalyticsEvent("tab_key_press", {}); };

  useEffect(() => {
    window.addEventListener("keydown", tabKeyDownListener);
    return () => {
      window.removeEventListener("keydown", tabKeyDownListener);
    };
  }, []);

  const getRoute = () => {
    if (router.query.gclid) {
      return "a";
    } else if (router.query.msclkid) {
      return "b";
    } else if (router.query.fbclid) {
      return "f";
    } else if (router.query.adid) {
      return "s";
    } else {
      return "o";
    }
  };

  useEffect(() => {
    if (CookieCutter.get("userID") === undefined) {
      const dateNow = dateFormat(new Date(), "ddmmyy");
      const userID = uuidv4().replace(/-/g, "");

      CookieCutter.set("userID", `${dateNow}_${userID}`);
      setUserId(`${dateNow}_${userID}`);
    } else {
      setUserId(CookieCutter.get("userID")!);
    }
  }, []);

  useEffect(() => {
    if (!router.isReady) return;

    const dataLayer = window.dataLayer || [];

    // dataLayer.push({
    //   external_id: userId.split("_").splice(-1)[0],
    // });
    // dataLayer.push({
    //   email: crypto
    //     .createHash("sha256")
    //     .update(`${userId.split("_").splice(-1)[0]}@rent80.com`)
    //     .digest("hex"),
    // });
    dataLayer.push({
      event: "fb_pixel_trigger",
    });

    if (CookieCutter.get("startDate")) {
      if (new Date(CookieCutter.get("startDate")!) >= new Date()) {
        setStartDate(new Date(CookieCutter.get("startDate")!));
      } else {
        CookieCutter.set("startDate", new Date());
      }
    }

    if (CookieCutter.get("endDate")) {
      if (new Date(CookieCutter.get("endDate")!) >= new Date(CookieCutter.get("startDate")!)) {
        setEndDate(new Date(CookieCutter.get("endDate")!));
      } else {
        CookieCutter.set("endDate", new Date(new Date().getDate() + 1));
      }
    }

    if (CookieCutter.get("pickupLocationName")) {
      airportStore.setAirport(
        CookieCutter.get("pickupLocationName") || "",
        CookieCutter.get("pickupLocationCode") || "",
        CookieCutter.get("pickupLocationCity") || ""
      );
    }

    const regionCookie = CookieCutter.get("region");
    regionCookie && setRegion(regionCookie?.toLowerCase());

    const cityCookie = CookieCutter.get("city");
    // cityCookie && setCity(cityCookie)
    const returnRouteName = (routeKey: RouteKey) => {
      switch (routeKey) {
        case "a":
          return "ads";
        case "b":
          return "bing";
        case "f":
          return "facebook";
        case "s":
          return "apple";
        case "o":
          return "organic";
        default:
          return "unknown";
      }
    };

    if (userId.split("_").length === 3) {
      const savedDate = userId.split("_")[0];
      const savedId = userId.split("_").splice(-1)[0];
      CookieCutter.set("userID", `${savedDate}_${savedId}`);
      setUserId(`${savedDate}_${savedId}`);
    }

    // This ain't it, chief
    // var gaUserId = document?.cookie?.match(/_ga=(.+?);/)[1].split('.').slice(-2).join(".");
    // console.log(gaUserId)

    setTimeout(() => {
      saveUser({
        uid: userId.split("_").splice(-1)[0],
        label: `d1wc_c_${userId.split("_").join(`_${getRoute()}_`)}`,
        route: returnRouteName(getRoute()),
        countryCode: regionCookie || "us",
        gclid: router.query.gclid,
        msclkid: router.query.msclkid,
        fbclid: router.query.fbclid,
        adid: router.query.adid,
        lastSeen: new Date(),
      });
    }, 2000);

    setUserProperties(analytics, {
      userId: userId.split("_").splice(-1)[0],
      country: regionCookie || "US",
      city: cityCookie || "SF",
    });

    router.query.startDate && setStartDate(new Date(router.query.startDate[0]));
    router.query.endDate && setEndDate(new Date(router.query.endDate[0]));

    router.query.dropOffLocation && setDropOffLocation(router.query.dropOffLocation[0]);
  }, [router.isReady]);

  useEffect(() => {
    if (endDate <= startDate) {
      const newDate = new Date(startDate);
      newDate.setDate(startDate.getDate() + 1);
      setEndDate(newDate);
    }
  }, [startDate, endDate]);

  let adTries = 0;
  let sidTries = 0;

  useEffect(() => {
    if (startDate && endDate && pickupLocation) {
      getSid();
    } else return;
  }, [startDate, endDate, pickupLocation]);

  const getAd = async () => {
    if (storedSid === null) return;
    try {
      const res = await axios.post(
        `https://${process.env.NEXT_PUBLIC_ADS_API_URL}/api/ads/car/list/?countryCode=${region.toLowerCase()}&_sid_=${storedSid.current}`,
        {
          pickUpDate: dateFormat(startDate, "yyyy-mm-dd"),
          dropOffDate: dateFormat(endDate, "yyyy-mm-dd"),
          pickUpLocation: {
            type: pickupLocation?.displayType.type,
            locationQuery:
              pickupLocation?.displayType.type === "airport"
                ? pickupLocation?.ap
                : pickupLocation?.ctid,
          },
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${process.env.NEXT_PUBLIC_ADS_API_BEARER_TOKEN}`,
          },
        }
      );
      setAdData(res.data);

    } catch (err) {
      if (adTries < 3) {
        console.error("Failed to fetch Ad, retrying");
        console.error(err);
        adTries++;
        setTimeout(() => {
          getAd();
        }, 1000);
      }
    }
  };

  const getSid = async () => {
    if (region === undefined) return;
    if (storedSid.current) {
      getAd();
      return;
    }

    try {
      const res = await axios.get(
        `https://${process.env.NEXT_PUBLIC_ADS_API_URL}/api/ads/session/?` +
        `countryCode=${region.toLowerCase()}&` +
        `label=d1wc_c_${userId.split("_").join(`_${getRoute()}_`)}`,
        {
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: `Bearer ${process.env.NEXT_PUBLIC_ADS_API_BEARER_TOKEN}`,
          },
        }
      );
      storedSid.current = res.data.sid;
      getAd();

    } catch (err) {
      if (sidTries < 3) {
        console.error("Failed to fetch SID, retrying");

        sidTries++;
        setTimeout(() => {
          getSid();
        }, 1000);
      }
    }
  };

  return (
    <>
      <section id="search" className="relative">
        <div className="relative z-10 w-full">
          <Navbar bg={true} locale={locale} />
        </div>
        {/* Background image and gradient */}
        <div className="overflow-clip">
          <div className={classNames(type === "lp2" ? "hero-bg-dark-blue-gradient" : "hero-bg-dark-gradient", "absolute left-0 top-0 h-full w-full hidden lg:grid")}>
            {type !== "lp2" && (
              <Image
                priority
                src={type !== "airport" ? "/hero/hero-bg.webp" : "/airport/hero/airport.webp"}
                height={936}
                width={2020}
                className="hidden object-cover absolute min-w-full min-h-full bg-transparent lg:grid"
                alt="Hero section background"
              />
            )}
          </div>
          <div className="hidden absolute top-0 left-0 w-full h-full hero-bg-dark-gradient lg:grid" />
          <div className="hero-blue-gradient lg:hidden grid absolute left-0 top-0 h-[251px] rounded-b-[20px] w-full"></div>
        </div>
        <div className="grid lg:hidden relative p-[18px] pt-0 space-y-[6px] text-center">
          <h1 className={classNames("text-[22px] text-[#F1F4FF]", type === "lp2" ? "" : "font-bold")}>
            {!headText
              ? type === "airport"
                ? `Car Rental in ${locationName}`
                : type === "lp2"
                  ? "Save up-to 80% on Rental Cars"
                  : type === "lp3"
                    ? "Rent a Car. Anywhere, Anytime!"
                    : `Rent a Car in ${findLocale(locale).name}, Anywhere, Anytime!`
              : headText}
          </h1>
          <h2 className={classNames("text-sm text-[#DAE0EB] leading-[20px]", type === "lp2" && "hidden")}>
            {type == "airport"
              ? `Choose from a wide range of rental cars at ${locationName} at unbeatable prices`
              : type === "lp2"
                ? ""
                : "Compare prices and find the best rental cars at 75,000+ locations worldwide."}
          </h2>
        </div>
        {togglePickUpSearchPage && (
          <div className="fixed bg-white h-screen w-screen z-[600] top-0 left-0 md:hidden">
            <SearchPage
              id="dropoff_input_box"
              setTogglePage={(value: boolean) => setTogglePickUpSearchPage(value)}
              type={type}
              locale={router?.locale || "en"}
              tag="pickup_location"
              dropOff={false}
              inputName="pickup"
              logAnalyticsEvent={logAnalyticsEvent}
            />
          </div>
        )}
        {toggleDropOffSearchPage && (
          <div className="fixed bg-white h-screen w-screen z-[600] top-0 left-0 md:hidden">
            <SearchPage
              id="pickup_input_box"
              setTogglePage={(value: boolean) => setToggleDropOffSearchPage(value)}
              type={type}
              locale={router?.locale || "en"}
              tag="dropoff_location"
              dropOff={true}
              inputName="dropoff"
              logAnalyticsEvent={logAnalyticsEvent}
            />
          </div>
        )}
        <div className="relative max-w-7xl mx-auto  lg:pb-[60px]">
          <div
            className={classNames(
              "relative h-full w-full gap-[94px]  flex items-center",
              type === "lp2" ? "justify-center" : "justify-start"
            )}
          >
            {/* <<<>>>------------------------------<<<>>> Search Box <<<>>>------------------------------<<<>>> */}
            <div className={classNames(
              "lg:scale-90 h-full w-full mx-2  relative z-10 bg-white  rounded-xl  p-4 flex flex-col items-center justify-center search-box-shadow md:py-[30px] md:px-[30px]",
              type == "lp2" ? " mb-5 max-w-[437px] lg:max-w-[840px]" : "lg:max-w-[437px]"
            )}>
              <div className={classNames(
                "lg:flex hidden lg:flex-row items-center justify-between pb-6",
                type == "lp2" ? "lg:pb-6 w-full hidden lg:flex" : "lg:pb-12"
              )}>
                <h3 className={classNames(
                  "lg:flex text-center text-xl lg:text-2xl font-medium text-my-dark-blue",
                  type !== "lp2" && "hidden"
                )}>
                  Save up-to<span className="font-bold">&nbsp;80%&nbsp;</span> on Rental Cars
                </h3>
                {type === "lp2" && (
                  <ToggleSwitch
                    className="hidden lg:flex"
                    label={"Alert me when price drops"}
                    value={priceDropAlertToggle}
                    onChange={() => {
                      setPriceDropAlertToggle((val) => !val);
                    }}
                  />
                )}
              </div>

              <div className="flex flex-col  lg:gap-[16px] items-start  justify-evenly w-full ">
                <div className="w-full">
                  <div className={classNames(
                    "w-full flex flex-col justify-between",
                    type == "lp2" ? "items-start gap-0" : "items-center gap-4"
                  )}>
                    <p className="text-xs lg:text-lg px-2 font-bold text-[#194393] pb-0 lg:pb-2 w-full">
                      Pick-up
                    </p>
                    <div className={classNames(
                      "flex flex-col items-start justify-center w-full gap-4",
                      type === "lp2" && "lg:flex-row"
                    )}>
                      <div className={classNames(
                        "relative flex gap-1 h-[60px] items-center justify-start bg-[#F3F6F9] px-[10px] border-[1.5px] border-[#F3F6F9] rounded-[10px] w-full",
                        type === "lp2" ? "lg:w-1/2" : "",
                        selectedSearch === 1 ? "border !border-[#258FFF]" : ""
                      )}>
                        <div className={`grid absolute top-0 left-0 w-full h-full md:hidden rounded-[10px]`}
                          onClick={() => {
                            logAnalyticsEvent("pickup_location_clicked", {
                              page_title: document.title,
                            });
                            setTogglePickUpSearchPage(true);
                          }}
                        ></div>
                        <SearchInputBox
                          locationName={locationName}
                          id="pickup_input_box"
                          dropOff={false}
                          locale={"en" || router.locale}
                          tag="pickup_location"
                          type={type}
                          inputName="pickup"
                          logAnalyticsEvent={logAnalyticsEvent}
                          setSelectedSearch={setSelectedSearch}
                        />
                      </div>

                      <div className={classNames(
                        "bg-[#F3F6F9] h-[60px] rounded-[10px] w-full gap-2",
                        type === "lp2" && "lg:w-1/2"
                      )}>
                        <CustomDatePicker
                          label={"Start date"}
                          logKey="startdate_clicked"
                          minDate={new Date()}
                          selected={startDate}
                          onChange={(date: Date) => setStartDate(date)}
                        />
                      </div>
                    </div>
                  </div>
                </div>

                <div className={classNames(
                  "flex flex-col items-start justify-between gap-x-[23px] pb-6 lg:pb-0 w-full",
                  type === "lp2" ? "gap-0" : "gap-4"
                )}>
                  <p className="text-xs lg:text-lg px-2 pt-4 font-bold text-[#194393] lg:pb-2 text-start w-full">
                    Drop-off
                  </p>
                  <div className={classNames(
                    "flex flex-col items-start justify-center w-full gap-4",
                    type === "lp2" && "lg:flex-row"
                  )}>
                    {isDropOffEnabled && (
                      <div className={classNames(
                        "flex flex-col items-start justify-center w-full border-[1.5px] rounded-[10px] border-[#F3F6F9]",
                        type === "lp2" && "lg:w-1/2",
                        selectedSearch === 2 && "!border-[#258FFF]",
                        !isDropOffEnabled && "mt-4"
                      )}>
                        <div className={`relative flex px-[10px] gap-1 h-[60px]  items-center justify-start bg-[#F3F6F9] rounded-[10px] w-full`}>
                          <div
                            className="grid absolute top-0 left-0 w-full h-full md:hidden"
                            onClick={() => {
                              logAnalyticsEvent("dropoff_location_clicked", {
                                page_title: document.title,
                              });
                              setToggleDropOffSearchPage(true);
                            }}
                          ></div>

                          <SearchInputBox
                            setSelectedSearch={setSelectedSearch}
                            id="dropoff_input_box"
                            dropOff={true}
                            locale={router.locale || "en"}
                            tag="dropoff_location"
                            initialValue={dropOffLocation}
                            setSelectedValue={setDropOffLocation}
                            inputName="dropoff"
                            setLocationId={setLocationId}
                            logAnalyticsEvent={logAnalyticsEvent}
                          />
                          <div
                            className="absolute right-3 top-[18px] "
                            onClick={() => {
                              logAnalyticsEvent("different_dropoff_disabled", {});
                              setIsDropOffEnabled(false);
                            }}
                          >
                            <Image
                              src="/hero/close.svg"
                              height={24}
                              width={24}
                              alt="Close different dropoff"
                            />
                          </div>
                        </div>
                      </div>
                    )}
                    <div className={classNames("bg-[#F3F6F9] h-[60px] rounded-[10px] w-full gap-2", type === "lp2" && "lg:w-1/2")}>
                      <CustomDatePicker
                        label={"End date"}
                        logKey="enddate_clicked"
                        selected={endDate}
                        minDate={
                          new Date(
                            startDate.getFullYear(),
                            startDate.getMonth(),
                            startDate.getDate(),
                            startDate.getHours() + 6
                          )
                        }
                        maxDate={
                          new Date(
                            startDate.getFullYear() + 1,
                            startDate.getMonth(),
                            startDate.getDate()
                          )
                        }
                        onChange={(date: Date) => setEndDate(date)}
                      />
                    </div>

                    {!isDropOffEnabled && (
                      <div
                        className={classNames(
                          "flex flex-col items-start justify-center w-full cursor-pointer",
                          type === "lp2" && "lg:w-1/2"
                        )}
                        onClick={() => {
                          logAnalyticsEvent("different_dropoff_enabled", {});
                          setIsDropOffEnabled(true);
                        }}
                      >
                        <div className="relative flex px-[10px] gap-[7px] h-[60px]  items-center justify-center bg-[#D6EAFF]  rounded-[10px] w-full">
                          <Image
                            src="/hero/add-dropoff.svg"
                            alt="location pin icon"
                            height={24}
                            width={24}
                            className="w-auto h-auto"
                          />
                          <p className="font-semibold text-[15px] text-[#004DE3]">
                            Different drop-off
                          </p>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <button className="hidden" id="secondary-submit" onClick={() => {
                console.log("secondary-submit clicked");
              }} />
              <button
                name="submit"
                id="search_button"
                className="search-btn-gradient mx-auto rounded-[10px] text-white lg:h-[58px] h-[60px] px-6 text-base lg:text-lg font-medium lg:font-semibold w-full lg:mt-[21px] xl:min-w-[159px]"
                onClick={() => {
                  CookieCutter.set("startDate", startDate);
                  CookieCutter.set("endDate", endDate);
                  CookieCutter.set("dropOffLocation", dropoffStore);
                  CookieCutter.set("pickupLocationName", airportStore.displayName);
                  CookieCutter.set("pickupLocationCode", airportStore.code);
                  CookieCutter.set("pickupLocationCity", airportStore.citynamesshort);
                  let destination = "";
                  let kayakDestination = "";
                  if (dropoffStore) {
                    destination = `${dropoffStore?.code || dropoffStore?.citynamesshort || ""}`;
                    kayakDestination = `${destination}`;
                    if (kayakDestination != "") {
                      kayakDestination = kayakDestination + "/";
                    }
                  }

                  let start: string;
                  if (airportStore?.displayName.includes("[")) {
                    start = airportStore?.displayName.split("[")[1].split("]")[0];
                  } else if (airportStore?.displayName.includes("(")) {
                    start = airportStore?.displayName.split("(")[1].split(")")[0];
                  } else if (airportStore?.code) {
                    start = airportStore?.code;
                  } else {
                    start = airportStore?.citynamesshort;
                  }

                  const dateWithTime = startDate;
                  dateWithTime.setTime(startDate.getTime() + 60 * 60 * 1000);

                  const endateWithTime =
                    endDate > new Date(startDate.getTime() + 24 * 60 * 60 * 1000)
                      ? endDate
                      : new Date(startDate.getTime() + 24 * 60 * 60 * 1000);
                  const kayakURL = `https://${getURL(region)}/in?` +
                    'a=kan_187653_561320&' +
                    'encoder=27_1&' +
                    `enc_cid=d1wc_c_${userId.split("_").join(`_${getRoute()}_`)}&` +
                    'enc_pid=deeplinks&' +
                    `enc_eid=${clickId}&` +
                    `enc_lid=r80_${type}${type == "airport" ? `_${airport}` : ""}&url=/cars/${start === "" ? "LHR" : start}/` +
                    kayakDestination +
                    `${dateFormat(dateWithTime, "yyyy-mm-dd")}/${dateFormat(endateWithTime, "yyyy-mm-dd")}${airportStore.agency === "" ? "" : `?fs=caragency=${airportStore.agency}`}`;

                  localStorage.setItem("kayakurl", kayakURL);
                  let dateFrom = dateFormat(dateWithTime, "yyyy-mm-dd");
                  let dateEnd = dateFormat(endateWithTime, "yyyy-mm-dd");

                  const cheapFlightURL = new URL("https://www.autorentals.com/remotesearch");
                  if (destination?.length === 0) {
                  } else {
                    cheapFlightURL.searchParams.append("dl", destination);
                  }

                  if (start?.length === 0) {
                    cheapFlightURL.searchParams.append("pl", "LON");
                  } else {
                    cheapFlightURL.searchParams.append("pl", start);
                  }

                  cheapFlightURL.searchParams.append("pd", dateFormat(dateWithTime, "yyyymmdd"));
                  cheapFlightURL.searchParams.append("pt", dateFormat(dateWithTime, "hhmmtt"));
                  cheapFlightURL.searchParams.append("dd", dateFormat(endateWithTime, "yyyymmdd"));
                  cheapFlightURL.searchParams.append("dt", dateFormat(endateWithTime, "hhmmtt"));
                  cheapFlightURL.searchParams.append("tpm", `d1wc_c_${userId.split("_").join(`_${getRoute()}_`)}`);
                  cheapFlightURL.searchParams.append("cid", "98001");

                  logAnalyticsEvent("searchcar_clicked", {
                    pickup_location: start,
                    dropoff_location: destination,
                    startdate: dateWithTime.toISOString().split("T")[0],
                    enddate: endDate.toISOString().split("T")[0],
                    start_time: dateWithTime.toISOString().split("T")[1].replace("Z", ""),
                    end_time: endDate.toISOString().split("T")[1].replace("Z", ""),
                    different_dropoff: isDropOffEnabled ? 1 : 0,
                    button_text: "Search Cars",
                  });
                  if (type === "lp3") {
                    setLp3Submitted(true);
                  } else {
                    if (airportStore.code == "" || airportStore.citynamesshort == "") {
                      popupStore.setContent("Please select a location first", true, 2000);
                    } else {
                      if (isInAppBrowser) {
                        window.location.assign(kayakURL);
                      } else {
                        localStorage.setItem("cheapflightsurl", cheapFlightURL.href);
                        window.open(kayakURL, "_blank");
                        window.location.assign(cheapFlightURL.href);
                      }
                    }
                  }
                }}
              >
                Search Cars
              </button>
              {type === "lp2" && (
                <ToggleSwitch
                  className="mt-3 lg:hidden"
                  label={"Alert me when price drops"}
                  value={priceDropAlertToggle}
                  onChange={() => {
                    setPriceDropAlertToggle((val) => !val);
                  }}
                />
              )}
            </div>
            {/* <<<>>>----------------------------<<<>>> End Search Box <<<>>>----------------------------<<<>>> */}
            {type !== "lp2" && (
              <div className="z-20 hidden w-full h-[75vh] lg:flex flex-col  py-[112px]">
                <div className="h-full space-y-[1.5rem]">
                  <h1 className="text-[#F1F4FF] font-bold text-[40px]">
                    {!headText
                      ? type === "airport"
                        ? `Car Rental in ${locationName}`
                        : type === "lp2"
                          ? "Save up-to 80% on Rental Cars"
                          : type === "lp3"
                            ? "Rent a Car. Anywhere, Anytime!"
                            : `Rent a Car in ${findLocale(locale).name}, Anywhere, Anytime!`
                      : headText}
                  </h1>
                  <p className="font-normal text-[18px] text-[#DAE0EB]">
                    {!descText
                      ? type == "airport"
                        ? `Find the best car rental in ${locationName} at unbeatable prices. Choose a Car rental deal from any top providers at ${locationName} and save big.`
                        : "Compare prices and find the best rental cars at 75,000+ locations worldwide."
                      : descText}
                  </p>
                </div>
                <Features />
              </div>
            )}
          </div>
        </div>
        {type === "lp3" && lp3Submitted && (
          <AdLoader
            countryCode={region}
            handleSubmit={() => {
              CookieCutter.set("startDate", startDate);
              CookieCutter.set("endDate", endDate);
              CookieCutter.set("dropOffLocation", dropoffStore);
              CookieCutter.set("pickupLocationName", airportStore.displayName);
              CookieCutter.set("pickupLocationCode", airportStore.code);
              CookieCutter.set("pickupLocationCity", airportStore.citynamesshort);
              let destination = "";
              let kayakDestination = "";
              if (dropoffStore) {
                destination = `${dropoffStore?.code || dropoffStore?.citynamesshort || ""}`;
                kayakDestination = `${destination}`;
                if (kayakDestination != "") {
                  kayakDestination = kayakDestination + "/";
                }
              }
              // console.log(airportStore);

              let start: string;

              if (airportStore?.displayName.includes("[")) {
                start = airportStore?.displayName.split("[")[1].split("]")[0];
              } else if (airportStore?.displayName.includes("(")) {
                start = airportStore?.displayName.split("(")[1].split(")")[0];
              } else if (airportStore?.code) {
                start = airportStore?.code;
              } else {
                start = airportStore?.citynamesshort;
              }

              const dateWithTime = startDate;
              dateWithTime.setTime(startDate.getTime() + 60 * 60 * 1000);

              const endateWithTime = endDate;
              endateWithTime.setTime(endDate.getTime() + 60 * 60 * 1000);

              const kayakURL = `https://${getURL(region)}/in?` +
                'a=kan_187653_561320&' +
                'encoder=27_1&' +
                `enc_cid=d1wc_c_${userId.split("_").join(`_${getRoute()}_`)}&` +
                'enc_pid=deeplinks&' +
                `enc_eid=${clickId}&` +
                `enc_lid=r80_${type}${type == "lp3" ? `_lp3` : ""}&` +
                `url=/cars/${start === "" ? "LHR" : start}/` +
                kayakDestination +
                `${dateFormat(dateWithTime, "yyyy-mm-dd")}/${dateFormat(endDate, "yyyy-mm-dd")}${airportStore.agency === "" ? "" : `?fs=caragency=${airportStore.agency}`}`;

              // https://www.kayak.com/in?a=kan_187653_561320&encoder=27_1&enc_cid=[USER_ID]_ka&enc_pid=r80_lp1_deeplinks&enc_eid=[CLICKID]&enc_lid=r80_[CATEGORY]_[CITYNAME]&url=/cars/[SEARCH_PARAMS]
              // https://www.kayak.com/in?a=kan_187653_561320&encoder=27_1&enc_cid=12345_ka&enc_pid=r80_lp1_deeplinks&enc_eid=123456876&enc_lid=r80_airport_myrtle&url=/cars/LHR/2021-09-30-12h/2021-10-01-12h

              localStorage.setItem("kayakurl", kayakURL);
              let dateFrom = dateFormat(dateWithTime, "yyyy-mm-dd");
              let dateEnd = dateFormat(endDate, "yyyy-mm-dd");

              const cheapFlightURL = new URL("https://www.autorentals.com/remotesearch");
              if (destination?.length === 0) {
              } else {
                cheapFlightURL.searchParams.append("dl", destination);
              }

              if (start?.length === 0) {
                cheapFlightURL.searchParams.append("pl", "LON");
              } else {
                cheapFlightURL.searchParams.append("pl", start);
              }

              cheapFlightURL.searchParams.append("pd", dateFormat(startDate, "yyyymmdd"));
              cheapFlightURL.searchParams.append("pt", dateFormat(startDate, "hhmmtt"));
              cheapFlightURL.searchParams.append("dd", dateFormat(endDate, "yyyymmdd"));
              cheapFlightURL.searchParams.append("dt", dateFormat(endDate, "hhmmtt"));
              cheapFlightURL.searchParams.append(
                "tpm",
                `d1wc_c_${userId.split("_").join(`_${getRoute()}_`)}`
              );
              cheapFlightURL.searchParams.append("cid", "98001");

              logAnalyticsEvent("searchcar_clicked", {
                pickup_location: start,
                dropoff_location: destination,
                startdate: dateWithTime.toISOString().split("T")[0],
                enddate: endDate.toISOString().split("T")[0],
                start_time: dateWithTime.toISOString().split("T")[1].replace("Z", ""),
                end_time: endDate.toISOString().split("T")[1].replace("Z", ""),
                different_dropoff: isDropOffEnabled ? 1 : 0,
                button_text: "Search Cars",
              });

              if (airportStore.code == "" || airportStore.citynamesshort == "") {
                popupStore.setContent("Please select a location first", true, 2000);
              } else {
                if (isInAppBrowser) {
                  setTimeout(() => {
                    window.location.assign(kayakURL);
                  }, 10000);
                } else {
                  localStorage.setItem("cheapflightsurl", cheapFlightURL.href);
                  window.open(kayakURL, "_blank");
                  setTimeout(() => {
                    window.location.assign(cheapFlightURL.href);
                  }, 10000);
                }
              }
            }}
          />
        )}
      </section>
    </>
  );
};

export default observer(SearchBox);
